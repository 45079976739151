import React from "react"
import clx from "classnames"
import { makeStyles } from "@material-ui/styles"
import Grid from "@material-ui/core/Grid"

const useStyles = makeStyles(theme => ({
  common: {
    marginTop: "1rem",
    marginBottom: "1rem",

    [theme.breakpoints.only("xs")]: {
      marginBottom: 0,
      paddingLeft: "0.5rem",
      paddingRight: "0.5rem",
    },
    [theme.breakpoints.only("sm")]: {
      paddingLeft: "2rem",
      paddingRight: "2rem",
    },
    [theme.breakpoints.only("md")]: {
      marginBottom: "2rem",
    },
    [theme.breakpoints.up("lg")]: {
      marginBottom: "3rem",
    },
  },

  left: {
    [theme.breakpoints.up("md")]: {
      paddingRight: "2rem",
    },
  },
  right: {
    [theme.breakpoints.up("md")]: {
      paddingLeft: "2rem",
    },
  },
}))

/**
 * Left and Right Grid splitted in the middle
 * @param {} param0
 */
const GridItem = ({ children, position = "left", ...props }) => {
  const classes = useStyles()

  const className = clx(
    classes.common,
    position === "right" ? classes.right : classes.left
  )

  return (
    <Grid item className={className} {...props}>
      {children}
    </Grid>
  )
}

export default GridItem
