import React, { useState } from "react"
import Layout from "../components/layout/layout"
import Seo from "../components/seo"
import Hero from "../components/home/hero"
// import Features from "../components/home/features"
import { makeStyles } from "@material-ui/core/styles"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import { graphql } from "gatsby"
import CodeExample from "../components/home/CodeExample"
// import { HomeWebAppsZapierImage } from "../components/home/HomeWebAppsZapierImage"
import { CompatibleLibraries } from "../components/landingPage/CompatibleLibraries"
import CLIOverview from "../components/CLIOverview"

const useStyles = makeStyles(theme => ({
  startPart: {
    background: `linear-gradient(to top, #0f4c81, ${theme.palette.primary.dark})`,
    //minHeight: "Divider40vh",
  },
  featurePart: {
    minHeight: "100%",
  },
  codeExampleLeft: {
    marginTop: "10px",
    marginRight: "10px",
  },
  codeExampleRight: {},
  developerArea: {
    marginBottom: "60px",
    // backgroundColor: "#0a355a",
    // height: "400px",
    // color: "white"
  },
  documentationArea: {
    marginBottom: "60px",
    // backgroundColor: "#ff6e40",
    // height: "200px",
  },
  noCodeArea: {
    marginBottom: "60px",
  },
  noCodeSection: {
    background: theme.palette.primary.dark,
    padding: "40px 0 30px 0",
  },
  divider: {
    margin: "15px 0",
  },
  h2: {
    textAlign: "center",
    color: theme.palette.primary.main,
    // fontSize: "1.5rem",
    // fontWeight: 900,
    marginTop: "50px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
    //color: "#444",
  },
  h2LessMargin: {
    textAlign: "center",
    color: theme.palette.primary.main,
    marginTop: "10px",
    marginBottom: "20px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },

  h2Invert: {
    color: "#fff",
    // paddingTop: "50px",
    fontSize: "1.75rem",
    fontWeight: "600",
  },
  h3: {
    margin: 0,
  },
  text: {
    fontSize: "1.15rem",
    textAlign: "left",
    padding: "5px 40px",
  },
  textInvert: {
    color: "#fff",
  },
  textLeft: {
    fontSize: "1.1rem",
    paddingRight: "40px",
  },
  textRight: {
    fontSize: "1.1rem",
    paddingLeft: "40px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "20px",
      paddingLeft: 0,
    },
  },
  list: {
    listStyle: "circle",
  },
}))

const IndexPage = ({ data, location }) => {
  const classes = useStyles()
  const [isFirstTranslated, setFirstTranslated] = useState(false)
  // const [isSecondTranslated, setSecondTranslated] = useState(false);
  const image = data

  return (
    <Layout>
      <div className={classes.startPart}>
        <Container maxWidth="lg">
          <Hero
            title="Fast & Simple Localization"
            subtitle="Translate Software, Apps, Games & Documentations"
          />
        </Container>
      </div>

      <Container maxWidth="lg" className={classes.developerArea}>
        <Grid container justify="space-between" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2}>
              Easily Translate i18n Locales & Markdown Files
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.textLeft}>
              Use Simpleen to translate your software and documentation - fast
              and customized. You can easily machine translate i18n locales in{" "}
              <strong>JSON</strong>, Java properties, PHP Arrays, YAML and other
              commonly used formats as well as <strong>Markdown</strong> text.{" "}
              <br />
              Simply copy/paste your code or documentation into our Web
              Translator and receive instant results. Simpleen manages the
              correct handling of the format, the context and the glossary.
              <br />
              Save time and money in your multilingual projects by using
              Simpleen for your prototyping or to implement successful
              international design early on.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <CodeExample
              codeFirst={data.jsonFirst}
              codeSecond={data.jsonSecond}
              langCodeFirst="en"
              langCodeSecond="es"
              isTranslated={isFirstTranslated}
            />

            <CodeExample
              codeFirst={data.docuExample}
              codeSecond={data.docuExampleTranslated}
              langCodeFirst="en"
              langCodeSecond="fr"
              isTranslated={isFirstTranslated}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" style={{ textAlign: "center" }}>
              <Button
                style={{ marginLeft: "auto", marginRight: "auto" }}
                variant="contained"
                color="primary"
                onClick={() => {
                  window &&
                    window.plausible &&
                    window.plausible(
                      isFirstTranslated
                        ? "Clicked Revert Example"
                        : "Clicked Translate Example"
                    )
                  setFirstTranslated(!isFirstTranslated)
                }}
              >
                {isFirstTranslated ? "Revert" : "Translate"}
              </Button>
            </Typography>
          </Grid>
        </Grid>
      </Container>

      <CompatibleLibraries currentPage="homepage" />
      <Container maxWidth="lg">
        <CLIOverview
          fluidImage={data.CLIToolImage.childImageSharp.gatsbyImageData}
          title="Continuous Localization (CLI)"
          textSegments={[
            "Choose your technologies & project structures",
            "Get instant translations during development",
            "Speed up your localization process",
          ]}
          callToAction="CLI Documentation"
          to="/documentation/cli-reference"
        />
      </Container>

      {/*
      <Container maxWidth="lg" className={classes.noCodeArea}>
        <Grid container justify="space-around" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h2" className={classes.h2LessMargin}>
              Automate Translation Workflows with Zapier
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={6}>
            <HomeWebAppsZapierImage />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" className={classes.textRight}>
              Simpleen enables you to easily connect <strong>DeepL</strong> to
              other WebApps. Use it with Zapier to integrate machine translation
              into your Custom <strong>NoCode</strong> workflow. <br />
              Optimize your workflow even further by adding{" "}
              <strong>customized</strong> translation into your process by using
              your glossary.
            </Typography>
          </Grid>
        </Grid>
      </Container>

      // Key features should be implemented again
      <Container maxWidth="lg">
        <Divider variant="fullWidth" />
      </Container>

      <Container maxWidth="lg" className={classes.featurePart}>
        <Features />
      </Container>
      */}

      <Seo
        general={{
          title: "Translate your i18n Locales | Get Instant Results",
          description:
            "Internationalize your Software and Documentation. Simply copy/paste your file to translate i18n locales - fast and customized.",
          path: location.pathname,
          image: image.twitter.childImageSharp.gatsbyImageData.src,
        }}
      />
    </Layout>
  )
}

export const indexQuery = graphql`
  query IndexQuery {
    twitter: file(relativePath: { eq: "seo/SEOImageDefault.png" }) {
      childImageSharp {
        gatsbyImageData(
          width: 1200
          quality: 95
          placeholder: BLURRED
          layout: FIXED
        )
      }
    }
    CLIToolImage: file(relativePath: { eq: "home/CLIToolCommands.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
    jsonFirst: markdownRemark(frontmatter: { slug: { eq: "/codeExample1" } }) {
      html
    }
    jsonSecond: markdownRemark(frontmatter: { slug: { eq: "/codeExample2" } }) {
      html
    }
    docuExample: markdownRemark(frontmatter: { slug: { eq: "/docuExample" } }) {
      html
    }
    docuExampleTranslated: markdownRemark(
      frontmatter: { slug: { eq: "/docuExampleTranslated" } }
    ) {
      html
    }
  }
`

export default IndexPage
